import React from 'react';
import CurateForm from "./CurateForm";

/**
 * Top level component for DAM project - it's the app container basically.
 *
 *
 */

class DetailView extends React.Component {

    constructor(props) {
        super(props);
        this.escKey = this.escKey.bind(this);
        this.startCurate = this.startCurate.bind(this);
        this.getFileElements = this.getFileElements.bind(this);
        this.stopPropagation = this.stopPropagation.bind(this);
        const file = this.props.file;
        let initialState = {};
        if (file){
            initialState = file.getEditableMetaDataInstance();
            initialState.fileId = file.id;
        }
        initialState.view = this.props.view;
        this.state = initialState;
    }

    escKey(event){
        if(event.keyCode === 27) {
            this.props.cancelHandler();
        }
    }

    componentDidMount(){
        document.addEventListener("keydown", this.escKey, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escKey, false);
    }

    // stops mouse clicks bubbling up to the main modal window, which would cause it to be cancelled
    stopPropagation(e){
        e.stopPropagation();
    }

    startCurate(){
        this.props.startCurate(this.state.fileId);
    }

    // doesn't quite warrant its own component
    getFileElements() {
        const f = this.props.file;
        let elements = [];
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Subject Identities</div>
                <div>{f.studIds}</div>
            </div>
        );
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Location</div>
                <div>{f.location}</div>
            </div>
        );
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Owner</div>
                <div>{f.owner}</div>
            </div>
        );
        if (this.props.ownerMetaData && this.props.ownerMetaData[f.owner]){
            const o = this.props.ownerMetaData[f.owner];
            if (o.attribution.length > 0){
                elements.push(
                    <div className="detailCell" key={elements.length}>
                        <div className="detailLabel">Owner Attribution</div>
                        <div>{o.attribution}</div>
                    </div>
                );
            }
            if (o.restrictions.length > 0){
                elements.push(
                    <div className="detailCell" key={elements.length}>
                        <div className="detailLabel">Owner Restrictions</div>
                        <div>{o.restrictions}</div>
                    </div>
                );
            }
        }
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Creator</div>
                <div>{f.creator}</div>
            </div>
        );
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Internal Rights</div>
                <div>{f.internalRights}</div>
            </div>
        );
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">External Rights</div>
                <div>{f.externalRights}</div>
            </div>
        );
        let mr = '';
        if (f.hasModelReleaseFolder()){
            mr = f.modelReleaseFolder;
        }
        let mrs = '';
        if (f.hasModelReleaseComplete()){
            mrs = ` (${f.modelReleaseComplete})`;
        }
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Model Releases{mrs}</div>
                <div>{mr}</div>
            </div>
        );
        if ((f.isImageFile() || f.isVideoFile()) && f.hasDimensions()){
            let label = 'Dimensions';
            if (f.isVideoFile()){
                label = 'Resolution';
            }
            elements.push(
                <div className="detailCell" key={elements.length}>
                    <div className="detailLabel">{label}</div>
                    <div>{f.getDisplayDimensions()}</div>
                </div>
            );
        }
        if (f.hasSize()){
            elements.push(
                <div className="detailCell" key={elements.length}>
                    <div className="detailLabel">Size</div>
                    <div>{f.getDisplaySize()}</div>
                </div>
            );
        }
        if (f.isVideoFile() && f.hasRunningTime()){
            elements.push(
                <div className="detailCell" key={elements.length}>
                    <div className="detailLabel">Running Time</div>
                    <div>{f.getDisplayRunningTime()}</div>
                </div>
            );
        }
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Media Creation Date</div>
                <div>{f.getMediaDate()}</div>
            </div>
        );
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Added To System</div>
                <div>{f.getCreatedDate()}</div>
            </div>
        );
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Dominant Colors</div>
                <div>{f.dominantColors}</div>
            </div>
        );
        let kEls = [];
        if (f.hasKeywords()){
            const kArray = f.getKeywordArray();
            for (let i in kArray){
                kEls.push(
                    <div className="keyword" key={kEls.length}>
                        {kArray[i]}
                    </div>
                );
            }
        }
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Keywords</div>
                <div className="keywordContainer">{kEls}</div>
            </div>
        );
        let desc = '';
        if (f.hasDescription()) {
            desc = f.description;
        }
        elements.push(
            <div className="detailCell" key={elements.length}>
                <div className="detailLabel">Description</div>
                <div className="detailDescription">{desc}</div>
            </div>
        );

        return elements;
    }

    render() {
        const f = this.props.file;
        const title =
            <div className="modalTitle">{f.name}</div>;
        let downloadLink = '';
        let curateLink = '';
        if (f.hasDownloadLink() &&
            !(this.props.view === 'curate' || this.props.view === 'curateAdd')
            && !f.hasIncompleteModelRelease()
        ){
            downloadLink =
                <a className="anchorButton" href={f.downloadLink}>Download</a>;
        }
        let mainContent = '';
        if (this.props.view === 'detail'){
            mainContent =
                <>
                    <div className="modalSubtitle">Details</div>
                    {this.getFileElements()}
                </>;
            if (this.props.canCurate){
                curateLink = <div className="divButton" onClick={this.startCurate}>Curate</div>;
            }
        }
        else if (this.props.view === 'curate' || this.props.view === 'curateAdd'){
            mainContent =
                <CurateForm
                    file={f}
                    changeHandler={this.props.changeHandler}
                    cancelHandler={this.props.cancelHandler}
                    commonKeywords={this.props.commonKeywords}
                    commonColors={this.props.commonColors}
                    waiting={this.props.waiting}
                    ownerMetaData={this.props.ownerMetaData}
                />;
        }
        let bigThumb = '';
        if (f.hasThumbnail()){
            bigThumb =
                <div className="detailImageContainer">
                    <img className="detailImage" src={f.getLargerThumbnail()} />
                </div>;
        }
        let pLink = '';
        if (f.isVideoFile() && f.hasPreviewLink()){
            pLink =
                <div className="previewLinkContainer">
                    <a href={f.previewLink} className="previewLink" target="_blank">watch preview</a>
                </div>;
        }
        return (
            <div className="modal" onClick={this.props.cancelHandler}>
                <div className="modalClose">&times;</div>
                <div className="modalWindow" onClick={this.stopPropagation}>
                    <div id="modalContent">
                        {title}
                        <div className="modalSplitter">
                            <div className="msLeft">
                                {bigThumb}
                                {pLink}
                            </div>
                            <div className="msRight">
                                {mainContent}
                            </div>
                        </div>
                        <div className="detailButtonContainer">
                            {downloadLink}
                            {curateLink}
                        </div>
                    </div>
                </div>
            </div>
    );
    }

}

export default DetailView;