import React from 'react';
import InboxItem from "./InboxItem";
import DamSpinny from "./DamSpinny";

/**
 * Top level component for DAM project - it's the app container basically.
 *
 *
 */

class InboxDisplay extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const files = this.props.files;
        let renderFiles;
        if (this.props.waiting){
            renderFiles =
                <DamSpinny/>;
        }
        else if (this.props.errorMessage !== false){
            renderFiles =
                <div className="errorMessage">{this.props.errorMessage}</div>;
        }
        else {
            renderFiles = [];
            if (files.length > 0){
                for (let i in files){
                    // note that the file search excludes trashed files now, so test below is current irrelevant
                    if (!files[i].isFolder() && !files[i].isTrashed()){
                        renderFiles.push(
                            <InboxItem
                                key={i}
                                item={files[i]}
                                addToRepository={this.props.addToRepository}
                            />
                        );
                    }
                }
            }
            if (renderFiles.length === 0) {
                renderFiles =
                    <div className="authMessage">No Files Found</div>;
            }
        }
        return (
            <>
            <div className="sectionTitle">Files waiting to be added</div>
            <div className="inboxDisplayContainer">
                {renderFiles}
            </div>
            </>
        );
    }

}

export default InboxDisplay;