import React from 'react';
import {DamMetaDataModel} from "../classes/DamMetaDataModel";
import DataLists from "./DataLists";
import CommonKeywordsDisplay from "./CommonKeywordsDisplay";

class SearchForm extends React.Component {

    constructor(props){
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.handleSearchJoiner = this.handleSearchJoiner.bind(this);
        this.clearForm = this.clearForm.bind(this);
        this.findUncuratedFiles = this.findUncuratedFiles.bind(this);
        const sm = this.props.searchModel;
        this.state = sm.getSearchMetaDataObject();

    }

    handleChange(e){
        const name = e.target.name;
        this.setState({[name]: e.target.value});
    }

    handleKeyUp(e){
        console.log('key code' + e.keyCode);
        if (e.keyCode === 13){
            // they hit the enter key, submit the form
            this.handleSubmit(e);
        }
    }

    handleCheckboxChange(e){
        const name = e.target.name;
        const val = e.target.value;
        // this code is here to allow the user to totally unset a radio button
        if (this.state[name] === val){
            console.log('clearing radio');
            this.setState({[name]: ''});
        }
        else {
            this.handleChange(e);
        }
    }

    handleSearchJoiner(e){
        let nextJoiner = 'and';
        if (this.state.andOr === 'and'){
            nextJoiner = 'or';
        }
        this.setState({andOr: nextJoiner});
    }

    handleSubmit(e){
        const sm= new DamMetaDataModel();
        console.log('handle submit ' + JSON.stringify(this.state));
        sm.setMetaDataFromForm(this.state);
        this.props.searchHandler(sm);
    }

    clearForm(){
        // reset it to empty, but pre-select image type
        const empty = new DamMetaDataModel();
        empty.setFileTypeToImage();
        this.setState(empty.getSearchMetaDataObject());
    }

    findUncuratedFiles() {
        // the  first two line here are just to preserve the current state of the search form
        // even though its state is irrelevant for finding uncurated files
        const sm= new DamMetaDataModel();
        sm.setMetaDataFromForm(this.state);
        this.props.uncuratedHandler(sm);
    }

    render(){
        return (
            <div className="mainSection">
                <div className="sectionTitle">File Filters</div>
                <DataLists
                    ownerMetaData={this.props.ownerMetaData}
                />
                <form onSubmit={this.handleSubmit}>
                    <div className="searchFormOuter">
                        <div className="searchFormMainContainer">
                            <div className="searchFormDetailContainerWide">
                                <div className="searchFormLabel">Search For
                                    <span className="searchFormSpacer">
                                        (joined by <span className="searchFormAndOr" onClick={this.handleSearchJoiner}>
                                            {this.state.andOr}
                                        </span>)
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    name="searchString"
                                    value={this.state.searchString}
                                    onChange={this.handleChange}
                                    onKeyUp={this.handleKeyUp}
                                />
                                <CommonKeywordsDisplay
                                    commonKeywords={this.props.commonKeywords}
                                    keywordString={this.state.searchString}
                                    targetHandler={this.handleChange}
                                    target="searchString"
                                    title="Keywords"
                                    />
                                <CommonKeywordsDisplay
                                    commonKeywords={this.props.commonColors}
                                    keywordString={this.state.searchString}
                                    targetHandler={this.handleChange}
                                    target="searchString"
                                    title="Colors"
                                />
                            </div>
                            <div className="searchFormDetailContainer">
                                <div className="searchFormLabel">Location</div>
                                <input type="text" name="location" list="locations" value={this.state.location} onChange={this.handleChange}/>
                            </div>
                            <div className="searchFormDetailContainer">
                                <div className="searchFormLabel">File Type</div>
                                <input type="checkbox" name="fileType" value="image"
                                       checked={this.state.fileType === 'image'}
                                       onChange={this.handleCheckboxChange}
                                /> Image
                                <input type="checkbox" name="fileType" value="video"
                                       checked={this.state.fileType === 'video'}
                                       onChange={this.handleCheckboxChange}
                                /> Video
                                <input type="checkbox" name="fileType" value="file"
                                       checked={this.state.fileType === 'file'}
                                       onChange={this.handleCheckboxChange}
                                /> Other
                            </div>
                            <div className="searchFormDetailContainer">
                                <div className="searchFormLabel">Orientation</div>
                                <input type="checkbox" name="orientation" value="Q"
                                       checked={this.state.orientation === 'Q'}
                                       onChange={this.handleCheckboxChange}
                                /> Square
                                <input type="checkbox" name="orientation" value="L"
                                       checked={this.state.orientation === 'L'}
                                       onChange={this.handleCheckboxChange}
                                /> Landscape
                                <input type="checkbox" name="orientation" value="P"
                                       checked={this.state.orientation === 'P'}
                                       onChange={this.handleCheckboxChange}
                                /> Portrait
                            </div>
                            <div className="searchFormDetailContainer">
                                <div className="searchFormLabel">Model Releases</div>
                                <input type="checkbox" name="modelReleaseComplete" value="complete"
                                       checked={this.state.modelReleaseComplete === 'complete'}
                                       onChange={this.handleCheckboxChange}
                                /> Complete
                                <input type="checkbox" name="modelReleaseComplete" value="incomplete"
                                       checked={this.state.modelReleaseComplete === 'incomplete'}
                                       onChange={this.handleCheckboxChange}
                                /> Incomplete
                            </div>
                        </div>
                        <div className="searchFormButtonContainer">
                            <input type="button" className="buttonButton" name="subButton" value="Find Files" onClick={this.handleSubmit}/>
                            <div className="minorDivLinkContainer">
                                <span className="minorDivLink" onClick={this.clearForm}>clear form</span>
                                &nbsp;|&nbsp;
                                <span className="minorDivLink" onClick={this.findUncuratedFiles}>find empty curations</span>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

}

export default SearchForm;