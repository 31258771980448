
export class DamOwner{

    constructor(sheetRow){
        this.name = false;
        this.attribution = '';
        this.restrictions = '';
    }

    populateFromSheetRow(sheetRow){
        if (sheetRow[0]){
            this.name = sheetRow[0];
        }
        if (sheetRow[1]){
            this.attribution = sheetRow[1];
        }
        if (sheetRow[2]){
            this.restrictions = sheetRow[2];
        }
    }

    hasOwner(){
        return this.name !== false;
    }

}