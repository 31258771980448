import React from 'react';

class InboxItem extends React.Component {

    constructor(props) {
        super(props);
        this.addToRepository = this.addToRepository.bind(this);
    }

    addToRepository(){
        console.log('add to repository click');
        this.props.addToRepository(this.props.item.id);
    }

    render() {
        const f = this.props.item;
        const dateString = f.getMediaDateTime();
        const fileTypeLetter = f.getFileTypeShort();
        const fileSize = f.getDisplaySize();
        let thumbUrl = '';
        if (f.hasThumbnail()){
            const imgSrc = f.thumbUrl;
            thumbUrl =
                <img className="inboxImage" src={imgSrc}></img>;
        }
        let actionDiv =
            <div className="inboxAddButton" onClick={this.addToRepository}>Add</div>;
        if (f.isFolder()){
            actionDiv =
                <div className="inboxNotAddButton">folder</div>;
        }
        if (f.isTrashed()){
            actionDiv =
                <div className="inboxNotAddButton">trashed</div>;
        }
        return (
            <div className="inboxItem">
                <div className="inboxThumb">
                    {thumbUrl}
                </div>
                <div className="inboxType">
                    {fileTypeLetter}
                </div>
                <div className="inboxItemMain">
                    {f.name}
                    <br/>
                    <span className="inboxItemDate">{dateString}, {fileSize}</span>
                </div>
                {actionDiv}
            </div>
        );
    }

}
export default InboxItem;