import React from 'react';
import Loader from "react-loader-spinner";



// very lightweight wrapper around react-loader-spinner
class DamSpinny extends React.Component {

    constructor(props){
        super(props);
        let size = 5;
        if (this.props.size){
            size = this.props.size;
        }
        this.state = {size};
    }

    render () {
        const size = `${this.state.size}em`;
        return (
            <div className="spinny">
                <Loader
                    type="TailSpin"
                    height={size}
                    width={size}
                    color="var(--cOrange)"
                />
            </div>
        );
    }

}

export default DamSpinny;