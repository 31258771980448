import {gapi} from "gapi-script";
import {DamImageFile} from "./DamImageFile";
import {DamUser} from "./DamUser";
import {DamVideoFile} from "./DamVideoFile";
import {DamFile} from "./DamFile";
import {DamOwner} from "./DamOwner";
import {DamMetaDataModel} from "./DamMetaDataModel";

export class GoogleConnector {

    folderMimeType = 'application/vnd.google-apps.folder';
    fileProperties = 'files(id, name, thumbnailLink, imageMediaMetadata, videoMediaMetadata, hasThumbnail, ' +
        'size, webContentLink, webViewLink, description, ' +
        'mimeType, trashed, ' +
        'originalFilename, createdTime, modifiedTime, appProperties, parents) ';
    metaDataSheetId = process.env.REACT_APP_DAM_META_DATA_SHEET_ID;


    constructor(userStatusCallback){
        this.userStatusCallback = userStatusCallback;
        this.folderSet = false;
        this.driveId = false;
        this.inboxFolderId = false;
        this.repositoryFolderId = false;
        this.systemFolderId = false;
        this.nextPageToken = false;
        this.pageSize = 100;      // used for both inbox and search files
        this.currentQuery = false;
        this.initClient = this.initClient.bind(this);
        this.parseFileList = this.parseFileList.bind(this);
        this.internalGetFileList = this.internalGetFileList.bind(this);
        this.getMainFolderIdsAndUserInfo = this.getMainFolderIdsAndUserInfo.bind(this);
        this.setMainFolderId = this.setMainFolderId.bind(this);
        this.setDriveId = this.setDriveId.bind(this);
        this.getUserInfo = this.getUserInfo.bind(this);
        this.getOrCreateDateFolder = this.getOrCreateDateFolder.bind(this);
        this.hasMoreFiles = this.hasMoreFiles.bind(this);
        this.getMoreFiles = this.getMoreFiles.bind(this);
        this.searchForFiles = this.searchForFiles.bind(this);
        gapi.load('client:auth2', this.initClient);
    }

    initClient () {
        const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest','https://sheets.googleapis.com/$discovery/rest?version=v4'];

        // const SCOPES = 'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.metadata';
        // this wide scope has started populating thumbnailLink!
        const SCOPES = 'https://www.googleapis.com/auth/drive https://www.googleapis.com/auth/spreadsheets.readonly';
        const apiKey = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY;
        const clientId = process.env.REACT_APP_GOOGLE_DRIVE_CLIENT_ID;

        const uscb = this.userStatusCallback;
        gapi.client
            .init({
                apiKey: apiKey,
                clientId: clientId,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            })
            .then(
                function () {
                    // Listen for sign-in state changes.
                    gapi.auth2.getAuthInstance().isSignedIn.listen(uscb);

                    // Handle the initial sign-in state
                    const isi = gapi.auth2.getAuthInstance().isSignedIn.get();
                    uscb(isi);
                    if (isi === false){
                        // they're not signed in, sign them in
                        // this works for now, but we might have to button this at some
                        // stage 'cos folks might need to be able to sign out
                        gapi.auth2.getAuthInstance().signIn();
                    }
                    else {
                    }
                },
                function (error) {
                    // TODO: not being handled properly at the moment
                    console.log('Google Sign In Error: ' + error);
                }
            );
    };

    getUserInfo(callback){
        const repId = this.repositoryFolderId;
        gapi.client.drive.about
            .get({
                fields: 'user'
            })
            .then(function(response){
                if (response.result && response.result.user){
                    let user = new DamUser();
                    const gu = response.result.user;
                    user.populateFromGoogleUser(gu);
                    if (gu.permissionId){
                        gapi.client.drive.permissions
                            .get({
                                fileId: repId,
                                permissionId: gu.permissionId,
                                supportsAllDrives: true,
                            })
                            .then(function(response){
                                console.log('permission response ' + response.result.role);
                                if (response.result && response.result.role){
                                    user.setRole(response.result.role);
                                }
                                callback(user);
                            });
                    }
                }
            });
    }

    getMainFolderIdsAndUserInfo(callback){
        const srfid = this.setMainFolderId;
        const setDid = this.setDriveId;
        const getUserInfo = this.getUserInfo;
        let driveId = false;
        gapi.client.drive.drives
            .list()
            .then(function(response){
                const d = JSON.parse(response.body);
                for (let i in d.drives){
                    const driveName = process.env.REACT_APP_DAM_DRIVE_NAME;
                    console.log(d.drives[i].name + ' and ' + driveName);
                    if (d.drives[i].name === driveName){
                        driveId = d.drives[i].id;
                        setDid(driveId);
                        break;
                    }
                }
                console.log('driveId: ' + driveId);
                if (driveId !== false){
                    gapi.client.drive.files
                        .list({
                            corpora: 'drive',
                            includeItemsFromAllDrives: true,
                            supportsAllDrives: true,
                            driveId: driveId,
                            pageSize: 3,
                            fields: 'files(id, name)',
                            q: "name='VWS DAM Inbox' or name='Repository' or name='System'",       // hardcoded as they're common across dev and prod environments
                        })
                        .then(function (response) {
                            const r = JSON.parse(response.body);
                            for (let i in r.files){
                                srfid(r.files[i].name, r.files[i].id);
                            }
                            getUserInfo(callback);
                        });
                }
            });
    }

    setMainFolderId(fName, fId){
        console.log('set fId:' + fId + ' for ' + fName);
        this.folderSet = true;      // to stop (deter?) infinite loops
        if (fName === 'VWS DAM Inbox'){
            this.inboxFolderId = fId;
        }
        else if (fName === 'Repository'){
            this.repositoryFolderId = fId;
        }
        else if (fName === 'System'){
            this.systemFolderId = fId;
        }
    }

    setDriveId(id){
        this.driveId = id;
    }

    getFolderId(callback, folderName){
        const driveId = this.driveId;
        gapi.client.drive.files
            .list({
                corpora: 'drive',
                includeItemsFromAllDrives: true,
                supportsAllDrives: true,
                driveId: driveId,
                pageSize: 1,
                fields: 'files(id, name)',
                q: `mimeType='${this.folderMimeType}' and name='${folderName}'`,
            })
            .then(function (response) {
                const r = JSON.parse(response.body);
                if (r.files && r.files.length === 1){
                    callback(r.files[0].id);
                }
            });
    }

    /**
     *
     * get or create the date folder id of the passed in folder name
     *
     * if it doesn't exist, create it under the year folder it belongs to
     *
     * if the year folder doesn't exist, create that first, under the repository folder
     *
     * there's a bit of promise chaining going on here,  but given that we're only planning on year
     * and date folders (ie, only 2 levels of folders), I'm not too bothered
     *
     * @param callback
     * @param folderName
     */
    getOrCreateDateFolder(callback, folderName){
        console.log('getOrCreateDateFolder - ' + folderName);
        const rpId = this.repositoryFolderId;
        const mt = this.folderMimeType;
        const driveId = this.driveId;
        gapi.client.drive.files
            .list({
                corpora: 'drive',
                includeItemsFromAllDrives: true,
                supportsAllDrives: true,
                driveId: driveId,
                pageSize: 1,
                fields: 'files(id, name)',
                q: `mimeType='${mt}' and name='${folderName}'`,
            })
            .then(function (response) {
                const r = JSON.parse(response.body);
                if (r.files && r.files.length === 1){
                    console.log('found existing folder with id: ' + r.files[0].id);
                    callback(r.files[0].id);
                }
                else {
                    // create it
                    console.log('going to create folder - ' + folderName);
                    const yearFolder = folderName.substring(0, 4);
                    gapi.client.drive.files
                        .list({
                            corpora: 'drive',
                            includeItemsFromAllDrives: true,
                            supportsAllDrives: true,
                            driveId: driveId,
                            pageSize: 1,
                            fields: 'files(id, name)',
                            q: `mimeType='${mt}' and name='${yearFolder}'`,
                        })
                        .then(function(response){
                            const r = JSON.parse(response.body);
                            if (r.files && r.files.length === 1) {
                                console.log('found year folder');
                                const yearFolderId = r.files[0].id;
                                // create date folder
                                const fileMetadata = {
                                    name: folderName,
                                    mimeType: mt,
                                    parents: [yearFolderId]
                                };
                                gapi.client.drive.files
                                    .create({
                                    supportsAllDrives: true,
                                    resource: fileMetadata,
                                    fields: 'id'
                                    })
                                    .then (function(response) {
                                        if (response.result && response.result.id) {
                                            callback(response.result.id);
                                        }
                                    });
                            }
                            else {
                                // create year folder
                                console.log('going to create year folder ' + yearFolder + ' under folder ' + rpId);
                                const fileMetadata = {
                                    name: yearFolder,
                                    mimeType: mt,
                                    parents: [rpId]
                                };
                                console.log('file metadata: ' + JSON.stringify(fileMetadata));
                                gapi.client.drive.files
                                    .create({
                                        supportsAllDrives: true,
                                        resource: fileMetadata,
                                        fields: 'id'
                                    })
                                    .then (function (response) {
                                        console.log(JSON.stringify(response));
                                        if (response.result && response.result.id){
                                            // console.log('year folder created with id ' + file.id);
                                            // now create date folder
                                            const fileMetadata = {
                                                name: folderName,
                                                mimeType: mt,
                                                parents: [response.result.id]
                                            };
                                            gapi.client.drive.files
                                                .create({
                                                    supportsAllDrives: true,
                                                    resource: fileMetadata,
                                                    fields: 'id'
                                                })
                                                .then(function (response) {
                                                    if (response.result && response.result.id) {
                                                        callback(response.result.id);
                                                    }
                                                });
                                        }
                                    });
                            }
                        });
                }
            });
    }

    /**
     *
     * this is currently only planned to be used to move from inbox into the repository, so we might
     * be able to hard code remove parents to be the inbox folder id, but then again, I'm assuming we shouldn't
     * be adding shared files into the repository in the first place?!? so we might need to remove all parents
     * if there is more than one
     *
     * @param callback
     * @param folderId
     * @param file
     */
    moveFileToFolder(callback, folderId, file){
        const driveId = this.driveId;
        gapi.client.drive.files
            .update({
                supportsAllDrives: true,
                fileId: file.id,
                addParents: folderId,
                removeParents: file.parents,        // TODO - we might have to cope with multiple parents
                fields: 'id, parents'
            })
            .then(function(response){
                // TODO - check the status
               callback() ;
            });
    }

    getInboxFiles(callback){
        if (this.folderSet !== false){
            const q = `'${this.inboxFolderId}' in parents`;
            this.internalGetFileList(callback, q);
        }
    }

    /*
    getAllFilesInFolder(callback, folderId){
        const q = `'${folderId}' in parents`;
        this.internalGetFileList(callback, q);
    }
    */

    hasMoreFiles() {
        return this.nextPageToken !== false;
    }

    getMoreFiles(callback) {
        const pfl = this.parseFileList;
        if (this.nextPageToken !== false){
            let parameters = {
                corpora: 'drive',
                includeItemsFromAllDrives: true,
                supportsAllDrives: true,
                driveId: this.driveId,
                pageSize: this.pageSize,
                fields: this.fileProperties + ', nextPageToken',
                pageToken: this.nextPageToken,
                q: this.currentQuery
            };
            // only allowed to specify an order when not doing a keyword or stud id search
            // as they use fullText search, it automatically returns "most relevant" first
            if (this.currentQuery.indexOf('fullText') === -1){
                parameters.orderBy = 'createdTime desc';
            }

            gapi.client.drive.files
                .list(
                    parameters
                )
                .then(function (response) {
                    // console.log(response.body);
                    const res = JSON.parse(response.body);
                    pfl(callback, res);
                });
        }
    }

    searchForFiles(callback, q){
        if (this.folderSet !== false){
            if (q.trim().length > 0){
                q += ' and ';
            }
            q += ` not '${this.inboxFolderId}' in parents and not '${this.systemFolderId}' in parents and trashed = false `;
            this.internalGetFileList(callback, q);
        }
    }

    internalGetFileList(callback, query){
        console.log('gfl driveId:' + this.driveId);
        if (this.folderSet !== false){
            // const q = "'" + this.state.rootFolderId + "' in parents";
            // to search for app properties - "appProperties has {key='studId' and value='646'}"
            let q = `${query}`;
            this.currentQuery = q;
            const pfl = this.parseFileList;
            console.log(`q: ${q}`);
            let parameters = {
                corpora: 'drive',
                includeItemsFromAllDrives: true,
                supportsAllDrives: true,
                driveId: this.driveId,
                pageSize: this.pageSize,
                fields: this.fileProperties + ', nextPageToken',
                q: q
            };
            // only allowed to specify an order when not doing a keyword or stud id search
            // as they use fullText search, it automatically returns "most relevant" first
            if (q.indexOf('fullText') === -1){
                parameters.orderBy = 'createdTime desc';
            }
            gapi.client.drive.files
                .list(
                    parameters
                )
                .then(function (response) {
                    // console.log(response.body);
                    const res = JSON.parse(response.body);
                    pfl(callback, res);
                })
                .catch(function(response){
                   // console.log('we got to error ' + response.result.error.errors[0].message);
                    let errorMessage = 'Unknown Error';
                    if (response.result && response.result.error && response.result.error.errors && response.result.error.errors.length > 0){

                        errorMessage = response.result.error.errors[0].message;
                    }
                    callback({error: errorMessage});
                });
        }
    }

    downloadFile(callback, file){
        const fileId = file.id;
        const driveId = this.driveId;
        gapi.client.drive.files
            .get({
                corpora: 'drive',
                includeItemsFromAllDrives: true,
                supportsAllDrives: true,
                driveId: driveId,
                fileId: fileId,
                alt: 'media',
            })
            .then (function (response){
               callback(response);
            });
    }

    parseFileList(callback, response){
        let file;
        let fileList = [];
        if (response.files) {
            for (let i in response.files) {
                // little factory bit here (should this be in its own class factory somewhere?)
                const cf = response.files[i];
                if (cf.imageMediaMetadata){
                    file = new DamImageFile();
                }
                else if (cf.videoMediaMetadata){
                    file = new DamVideoFile();
                }
                else {
                    file = new DamFile();
                }
                file.populateFromGoogleFile(response.files[i]);
                fileList.push(file);
            }
        }
        if (response.nextPageToken){
            this.nextPageToken = response.nextPageToken;
            console.log('npt: ' + response.nextPageToken);
        }
        else {
            this.nextPageToken = false;
            console.log('npt: not found');
        }
        callback(fileList);
    }

    /**
     * we update app properties, and we also stick stud ids and keywords
     * into the write only indexableText field.
     *
     * GDrive will search in indexable text (and description) as part of the full text search
     *
     * @param callback
     * @param file
     */
    updateFileAppProperties(callback, file){
        const ap = file.getAppPropertiesMetaDataObject();
        const iText = file.getIndexableText();
        console.log('FAP: ' + JSON.stringify(ap));
        const fileId = file.id;
        const driveId = this.driveId;
        const description = file.description;
        if (ap !== false){
            // now call the API
            gapi.client.drive.files
                .update({
                    supportsAllDrives: true,
                    driveId: driveId,
                    fileId: fileId,
                    appProperties: ap,
                    contentHints: {indexableText: iText},
                    description: description
                })
                .then(function(response){
                    // TODO currently ignoring the response, assuming it was successful
                    callback();
                });
        }
    }

    getOwnerMetaData(callback){
        gapi.client.sheets.spreadsheets.values
            .get({
                spreadsheetId: this.metaDataSheetId,
                range: 'Owners'
            })
            .then(function(response){
                if (response.result.values && response.result.values.length > 1){
                    let owners = {};
                    for (let i in response.result.values){
                        if (i > 0){
                            let owner = new DamOwner();
                            owner.populateFromSheetRow(response.result.values[i]);
                            if (owner.hasOwner()){
                                owners[owner.name] = owner;
                            }
                        }
                    }
                    callback(owners);
                }
            });
    }

}