/**
 * a model representing the VWS meta data
 *
 * Note that most of this maps on to the appProperties of the GDrive file,
 * and the appProperties are limited in size (see issue #2), so we use a lot of single letter keys to maximize what
 * we can store in there - see setMetaDataFromAppProperties for example of mappings
 *
 */

export class DamMetaDataModel {
    
    ks = ' ';

    constructor(){
        // comments are the single letter keys these members map to in appProperties
        // note - if you add or remove from this first section here, also do the same in getUncuratedQ too
        this.studIds = '';                  // s
        this.location = '';                 // l
        this.owner = '';                    // o
        this.creator = '';                  // c
        this.internalRights = '';           // i
        this.externalRights = '';           // x
        this.keywords = '';                 // k
        this.orientation = '';              // r
        this.modelReleaseFolder = '';       // m
        this.modelReleaseComplete = '';     // n
        this.dominantColors = '';           // d
        // treated slightly differently, set in descendants of this class, assigned at time of addition to repository
        this.fileType = '';                 // f
        // editable media creation date - populated with info at time of addition to repository if known, see #3
        this.mediaCreationDate = '';        // w (short for when! My first choices of m or c already taken!)

        // not part of the appProperties, but part of logical metadata
        this.description = '';

        // used by search
        this.searchString = '';
        this.andOr = 'and';     // not sure it'll be a good thing to allow this to be settable through the UI, but the plumbing is now there
    }

    setField(fieldName, fieldValue){
        this[fieldName] = fieldValue;
        if (fieldName === 'keywords'){
            this.trimKeywords();
        }
    }

    setFileTypeToImage(){
        this.fileType = 'image';
    }

    trimKeywords() {
        if (this.keywords.length > 0){
            let keywordArray = this.getKeywordArray();
            for (let i in keywordArray){
                // remove white space around a single keyword
                keywordArray[i] = keywordArray[i].trim();
            }
            this.keywords = keywordArray.join(this.ks);
        }
    }

    getKeywordArray() {
        let a = [];
        if (this.hasKeywords()){
            a = this.keywords.split(this.ks);
        }
        return a;
    }

    getColorsArray() {
        let a = [];
        if (this.hasDominantColors()){
            a = this.dominantColors.split(this.ks);
        }
        return a;
    }

    getStudIdArray() {
        let a = [];
        if (this.hasStudIds()){
            a = this.studIds.split(this.ks);
        }
        return a;
    }

    setMetaDataFromForm(formObj){
        if ('studIds' in formObj){
            this.studIds = formObj.studIds;
        }
        if ('location' in formObj){
            this.location = formObj.location;
        }
        if ('owner' in formObj){
            this.owner = formObj.owner;
        }
        if ('creator' in formObj){
            this.creator = formObj.creator;
        }
        if ('internalRights' in formObj){
            this.internalRights = formObj.internalRights;
        }
        if ('externalRights' in formObj){
            this.externalRights = formObj.externalRights;
        }
        if ('keywords' in formObj){
            this.keywords = formObj.keywords;
            // this could be coming from a "search", so make sure the tags are in good order
            this.trimKeywords();
        }
        if ('fileType' in formObj){
            this.fileType = formObj.fileType;
        }
        if ('description' in formObj){
            this.description = formObj.description;
        }
        if ('searchString' in formObj){
            this.searchString = formObj.searchString;
        }
        if ('orientation' in formObj){
            this.orientation = formObj.orientation;
        }
        if ('modelReleaseFolder' in formObj){
            this.modelReleaseFolder = formObj.modelReleaseFolder;
        }
        if ('modelReleaseComplete' in formObj){
            this.modelReleaseComplete = formObj.modelReleaseComplete;
        }
        if ('dominantColors' in formObj){
            this.dominantColors = formObj.dominantColors;
        }
        if ('mediaCreationDate' in formObj){
            this.mediaCreationDate = formObj.mediaCreationDate;
        }
        // search form specific
        if ('andOr' in formObj){
            this.andOr = formObj.andOr;
        }
        // console.log('after studIds: ' + this.studIds);
    }

    setMetaDataFromAppProperties(metaObj){
        // console.log('appProperties: ' + JSON.stringify(metaObj));
        if ('s' in metaObj){
            this.studIds = metaObj.s;
        }
        if ('l' in metaObj){
            this.location = metaObj.l;
        }
        if ('o' in metaObj){
            this.owner = metaObj.o;
        }
        if ('c' in metaObj){
            this.creator = metaObj.c;
        }
        if ('i' in metaObj){
            this.internalRights = metaObj.i;
        }
        if ('x' in metaObj){
            this.externalRights = metaObj.x;
        }
        if ('k' in metaObj){
            this.keywords = metaObj.k;
        }
        if ('f' in metaObj){
            this.fileType = metaObj.f;
        }
        if ('r' in metaObj){ // r 'cos o is already taken
            this.orientation = metaObj.r;
        }
        if ('m' in metaObj) {
            this.modelReleaseFolder = metaObj.m;
        }
        if ('n' in metaObj) {
            this.modelReleaseComplete = metaObj.n;
        }
        if ('d' in metaObj) {
            this.dominantColors = metaObj.d;
        }
        if ('w' in metaObj) {
            this.mediaCreationDate = metaObj.w;
        }
    }


    /**
     * get object containing meta data fields that have values in them
     * -- note, we need to get all the values out of there, 'cos otherwise you can't resent an existing field to blank
     * - used to set appProperties in a Google file
     *
     * @returns {{}}
     */
    getAppPropertiesMetaDataObject() {
        let mdo = {};
        mdo.s = this.studIds;
        mdo.l = this.location;
        mdo.o = this.owner;
        mdo.c = this.creator;
        mdo.i = this.internalRights;
        mdo.x = this.externalRights;
        mdo.k = this.keywords;
        mdo.f = this.fileType;
        mdo.r = this.orientation;
        mdo.m = this.modelReleaseFolder;
        mdo.n = this.modelReleaseComplete;
        mdo.d = this.dominantColors;
        mdo.w = this.mediaCreationDate;
        return mdo;
    }

    /**
     * get object containing all meta data fields and assign values
     * to those fields that have them
     *
     * @returns {{}}
     */
    getSearchMetaDataObject() {
        // return everything that can be searched on
        let mdo = {};
        mdo.studIds = this.studIds;
        mdo.location = this.location;
        // mdo.owner = this.owner;
        // mdo.creator = this.creator;
        // mdo.internalRights = this.internalRights;
        // mdo.externalRights = this.externalRights;
        // mdo.keywords = this.keywords;
        mdo.fileType = this.fileType;
        mdo.searchString = this.searchString;
        mdo.orientation = this.orientation;
        // mdo.modelReleaseFolder = this.modelReleaseFolder;
        mdo.modelReleaseComplete = this.modelReleaseComplete;
        // mdo.dominantColors = this.dominantColors;
        mdo.andOr = this.andOr;
        return mdo;
    }

    getCurateMetaDataObject() {
        // return everything that can be curated on
        let mdo = {};
        mdo.studIds = this.studIds;
        mdo.location = this.location;
        mdo.owner = this.owner;
        mdo.creator = this.creator;
        mdo.internalRights = this.internalRights;
        mdo.externalRights = this.externalRights;
        mdo.keywords = this.keywords;
        mdo.fileType = this.fileType;
        mdo.description = this.description;
        mdo.orientation = this.orientation;
        mdo.modelReleaseFolder = this.modelReleaseFolder;
        mdo.modelReleaseComplete = this.modelReleaseComplete;
        mdo.dominantColors = this.dominantColors;
        mdo.mediaCreationDate = this.mediaCreationDate;
        return mdo;
    }

    hasStudIds() {
        return this.studIds !== false && this.studIds.length > 0;
    }

    hasLocation() {
        return this.location !== false && this.location.length > 0;
    }

    hasOwner() {
        return this.owner !== false && this.owner.length > 0;
    }

    hasCreator() {
        return this.creator !== false && this.creator.length > 0;
    }

    hasInternalRights() {
        return this.internalRights !== false && this.internalRights.length > 0;
    }

    hasExternalRights() {
        return this.externalRights !== false && this.externalRights.length > 0;
    }

    hasKeywords() {
        return this.keywords !== false && this.keywords.length > 0;
    }

    hasFileType() {
        return this.fileType !== false && this.fileType.length > 0;
    }

    hasOrientation() {
        return this.orientation !== false && this.orientation.length > 0;
    }

    hasModelReleaseFolder() {
        return this.modelReleaseFolder !== false && this.modelReleaseFolder.length > 0;
    }

    hasModelReleaseComplete() {
        return this.modelReleaseComplete !== false && this.modelReleaseComplete.length > 0;
    }

    hasIncompleteModelRelease() {
        return this.modelReleaseComplete === 'incomplete';
    }

    hasDominantColors() {
        return this.dominantColors !== false && this.dominantColors.length > 0;
    }

    setStudId(studId) {
        this.studIds = studId;
    }

    populateAutoFields() {
        // do nothing - to be overridden
    }

    /*
        next two methods use a combination of stud ids, keywords and colors to get the indexable text
        which is then searched during a search and to see what matches this file has with the search string.

        The fields that end up in the indexable text should also be the same fields that are searched for matches
     */

    getIndexableText() {
        let iText = this.keywords;
        if (this.studIds.length > 0){
            if (iText.length > 0){
                iText += ' ';
            }
            iText += this.studIds;
        }
        if (this.dominantColors.length > 0){
            if (iText.length > 0){
                iText += ' ';
            }
            iText += this.dominantColors;
        }
        return iText;
    }

    getSearchOrder() {
        let order = 'added to system, most recent first';
        if (this.searchString.length > 0){
            order = 'most relevant to search criteria';
        }
        return order;
    }

    // see if the search string contains known stud ids, keywords or colors
    getMatches(ss){
        let matches = [];
        const allWords = this.getKeywordArray().concat(this.getStudIdArray().concat(this.getColorsArray()));
        const a = ss.split(this.ks);
        for (let i in a){
            for (let j in allWords){
                if (a[i] == allWords[j]){
                    matches.push(a[i]);
                }
            }
        }
        return matches.join(' ');
    }

    // escape single quote
    esq(value) {
        return value.replace(/'/g, "\\'");
    }

    // For searching (Google specific)
    // we are not allowing all aspects to be searchable initially
    getQ() {
        let q = ''; //  - we're letting fileType determine what kind of files get brought back! `mimeType='image/jpeg' `;
        // value of searchString will be used to search indexableText (description and other things) to find things
        // it will encompass stud ids and keywords
        if (this.searchString.length > 0){
            const sStrings = this.searchString.split(this.ks);
            for (let i in sStrings){
                const sString = sStrings[i];
                q = this.addToQ(q,` fullText contains '${this.esq(sString)}' `);
            }
            q = ' ( ' + q + ' ) ';

        }
        if (this.location.length > 0){
            q = this.addToQAnd(q, ` appProperties has {key='l' and value='${this.esq(this.location)}'} `);
        }
        if (this.fileType.length > 0){
            q = this.addToQAnd(q,` appProperties has {key='f' and value='${this.esq(this.fileType)}'} `);
        }
        else {
            // we need this so that only curated files (that therefore have f set) are returned
            q = this.addToQAnd(q, ` (appProperties has {key='f' and value='image'} or appProperties has {key='f' and value='file'} or appProperties has {key='f' and value='video'})`);
        }
        if (this.orientation.length > 0){
            q = this.addToQAnd(q, ` appProperties has {key = 'r' and value='${this.esq(this.orientation)}'} `);
        }
        if (this.modelReleaseComplete.length > 0){
            q = this.addToQAnd(q, ` appProperties has {key = 'n' and value='${this.esq(this.modelReleaseComplete)}'} `);
        }
        return q;
    }

    addToQ(q, add){
        return this.addToQConnector(q, add, this.andOr);
    }

    addToQAnd(q, add){
        return this.addToQConnector(q, add, 'and');
    }

    addToQConnector(q, add, connector){
        let rq = q;
        if (q.length > 0){
            rq += ` ${connector} `;
        }
        rq += add;
        return rq;
    }

    // get a query that represents an uncurated asset, see #19
    getUncuratedQ(){
        let q = '';
        q = this.addToQAnd(q, ` appProperties has {key='s' and value=''} `);
        q = this.addToQAnd(q, ` appProperties has {key='l' and value=''} `);
        q = this.addToQAnd(q, ` appProperties has {key='o' and value=''} `);
        q = this.addToQAnd(q, ` appProperties has {key='c' and value=''} `);
        q = this.addToQAnd(q, ` appProperties has {key='i' and value=''} `);
        q = this.addToQAnd(q, ` appProperties has {key='x' and value=''} `);
        q = this.addToQAnd(q, ` appProperties has {key='k' and value=''} `);
        q = this.addToQAnd(q, ` appProperties has {key='m' and value=''} `);
        q = this.addToQAnd(q, ` appProperties has {key='n' and value=''} `);
        q = this.addToQAnd(q, ` appProperties has {key='d' and value=''} `);
        return q;
    }

}