import React from 'react';
import {DamImageFile} from '../classes/DamImageFile';

/**
 * Top level component for DAM project - it's the app container basically.
 *
 *
 */

class GalleryItemDisplay extends React.Component {

    constructor(props) {
        super(props);
        this.startDetailView = this.startDetailView.bind(this);
        this.getMatches = this.getMatches.bind(this);
    }

    startDetailView(){
        const file = this.props.file;
        this.props.detailHandler(file.id);
    }

    getMatches(){
        return '';
        /*

        it only really makes sense to show matches if you're doing an 'or', otherwise the matches shows
        all stud ids, keywords and color otherwise it wouldn't have matched in the first place unless it
        was via the description, then things just start to get weird.

        const ss = this.props.searchString;
        const f = this.props.file;
        const m = f.getMatches(ss);
        let matches = '';
        if (m.length > 0){
            matches =
                <div className="galleryItemItem" >Matches: {m}</div>;
        }
        return matches;
         */
    }

    render() {
        const f = this.props.file;
        let thumbUrl;
        if (f.hasThumbnail()){
            const imgSrc = f.thumbUrl;
            thumbUrl = <img className="galleryItemImage" src={imgSrc}></img>;
        }
        else {
            thumbUrl = '';
        }
        let elements = [];
        if ((f.isImageFile() || f.isVideoFile()) && f.hasDimensions()){
            let label = 'Dimensions';
            if (f.isVideoFile()){
                label = 'Res';
            }
            elements.push(
                <div className="galleryItemItem" key={elements.length}>
                    {label}: {f.getDisplayDimensions()}
                </div>
            );
        }
        if (f.hasSize()){
            elements.push(
                <div className="galleryItemItem" key={elements.length}>
                    Size: {f.getDisplaySize()}
                </div>
            );
        }
        if (f.isVideoFile() && f.hasRunningTime()){
            elements.push(
                <div className="galleryItemItem" key={elements.length}>
                    Running Time: {f.getDisplayRunningTime()}
                </div>
            );
        }
        let ids = <>&mdash;</>;
        if (f.hasStudIds()){
            ids = f.studIds;
        }
        elements.push(
            <div className="galleryItemItem" key={elements.length}>Ids: {ids}</div>
        );
        const mediaDT =
            <div className="galleryItemDate">Media created: {f.getMediaDate()}</div>;
        const createdDate =
            <div className="galleryItemDate">Added: {f.getCreatedDate()}</div>;

        const matches = this.getMatches();
        return (
          <div className="galleryItemDisplayContainer" onClick={this.startDetailView}>
              <div className="galleryItemName">{f.name}</div>
              <div className="galleryItemThumbContainer">{thumbUrl}</div>
              {elements}
              {matches}
              {mediaDT}
              {createdDate}
          </div>
        );
    }

}

export default GalleryItemDisplay;