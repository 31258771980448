import React from 'react';
import GalleryDisplay from "./GalleryDisplay";
import InboxDisplay from "./InboxDisplay";
import DamHeader from "./DamHeader";
import DetailView from "./DetailView";
import {GoogleConnector} from "../classes/GoogleConnector";
import {DamMetaDataModel} from "../classes/DamMetaDataModel";
import SearchForm from "./SearchForm";
import DamSpinny from "./DamSpinny";

/**
 * Top level component for DAM project - it's the app container basically.
 *
 *
 */

class Dam extends React.Component {

    // members;
    fileList = [];
    gc = false;
    addFile = false;
    searchModel = new DamMetaDataModel();
    commonKeywords = [];
    commonColors = [];

    constructor(props) {
        super(props);
        // have to bind all this stuff to the component
        this.updateSigninStatus = this.updateSigninStatus.bind(this);
        this.setUserInfo = this.setUserInfo.bind(this);
        this.setFileList = this.setFileList.bind(this);
        this.navClickHandler = this.navClickHandler.bind(this);
        this.curateChangeHandler = this.curateChangeHandler.bind(this);
        this.getCurrentFile = this.getCurrentFile.bind(this);
        this.getFileFromId = this.getFileFromId.bind(this);
        this.startCurate = this.startCurate.bind(this);
        this.startCurateAfterAdd = this.startCurateAfterAdd.bind(this);
        this.afterCurate = this.afterCurate.bind(this);
        this.addToRepository = this.addToRepository.bind(this);
        this.addFileToFolder = this.addFileToFolder.bind(this);
        this.addedToRepository = this.addedToRepository.bind(this);
        this.readyToCurate = this.readyToCurate.bind(this);
        this.getMoreFiles = this.getMoreFiles.bind(this);
        this.addToFileList = this.addToFileList.bind(this);
        this.searchHandler = this.searchHandler.bind(this);
        this.uncuratedHandler = this.uncuratedHandler.bind(this);
        this.startDownload = this.startDownload.bind(this);
        this.getDownload = this.getDownload.bind(this);
        this.startDetailView = this.startDetailView.bind(this);
        this.setCommonKeywords = this.setCommonKeywords.bind(this);
        this.setOwnerMetaData = this.setOwnerMetaData.bind(this);
        this.setCommonKeywords();   // set 'em!
        this.setCommonColors();
        this.searchModel.setFileTypeToImage();
        this.state = {
            signedIn: false,
            fileListSize: 0,
            debugInfo: '',
            navTab: 'search',
            currentFileId: false,
            currentUser: false,
            hasMoreFiles: false,
            waiting: false,
            currentError: false,
            ownerMetaData: {}
        };

    }

    componentDidMount() {
        // log them into the system to get started
        this.gc = new GoogleConnector(this.updateSigninStatus);
    }


    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */
    updateSigninStatus(isSignedIn) {
        const oldSignIn = this.state.signedIn;
        this.setState({signedIn: isSignedIn});
        if (isSignedIn && oldSignIn === false) {
            // we just got signed in, get a file list
            this.gc.getMainFolderIdsAndUserInfo(this.setUserInfo);
            // lets get meta data too
            this.gc.getOwnerMetaData(this.setOwnerMetaData);
        }
    };

    setUserInfo(user){
        this.setState({currentUser: user});
    };

    setOwnerMetaData(ownerData){
        this.setState({ownerMetaData: ownerData});
    }

    getMoreFiles() {
        if (this.state.hasMoreFiles){
            this.gc.getMoreFiles(this.addToFileList);
            this.setState({waiting: true});
        }
    }

    addToFileList(fileList){
        if ('error' in fileList){
            console.log('sfl ' + fileList.error);
            this.setState({currentError: fileList.error, hasMoreFiles: false, waiting: false});
        }
        else {
            this.fileList = this.fileList.concat(fileList);
            const count = this.fileList.length;
            console.log('fileListCount:' + count);
            const hasMore = this.gc.hasMoreFiles();
            this.setState({fileListSize: count, hasMoreFiles: hasMore, waiting: false});
        }
    }

    setFileList(fileList){
        if ('error' in fileList){
            console.log('sfl ' + fileList.error);
            this.setState({currentError: fileList.error, hasMoreFiles: false, waiting: false});
        }
        else {
            this.fileList = fileList;
            const count = this.fileList.length;
            const hasMore = this.gc.hasMoreFiles();
            this.setState({fileListSize: count, hasMoreFiles: hasMore, waiting: false});
        }
    }

    navClickHandler(tabItem){
        const ct = this.state.navTab;
        let waiting = this.state.waiting;
        let currentError = this.state.currentError;
        if (ct !== tabItem){
            if (tabItem === 'add'){
                // get inbox files
                this.fileList = []; // clear it out ahead of time
                this.gc.getInboxFiles(this.setFileList);
                waiting = true;
                currentError = false;
            }
            else if (tabItem === 'search'){
                console.log('about to search, ct is ' + ct);
                if (ct === 'add' || ct === 'curateAdd'){
                    // we just came from add, clear out the file list and re-do the search
                    this.fileList = [];
                    this.searchHandler(this.searchModel);
                    waiting = true;
                }
            }
            this.setState({navTab: tabItem, waiting: waiting, currentError: currentError});
        }
    }

    curateChangeHandler(state){
        const fileId = state.fileId;
        console.log('curateChangeHandler - fileId:' + JSON.stringify(fileId));
        for (let i in this.fileList){
            if (this.fileList[i].id == fileId){
                // set it locally
                this.fileList[i].setMetaDataFromForm(state);
                this.fileList[i].populateAutoFields();
                // now set it in the drive
                this.gc.updateFileAppProperties(this.afterCurate, this.fileList[i]);
                this.setState({waiting: true});
                break;
            }
        }
    }

    afterCurate(){
        this.setState({currentFileId: false, waiting: false});
        this.navClickHandler('search');
    }

    getCurrentFile() {
        let cf = false;
        if (this.state.currentFileId !== false){
            cf = this.getFileFromId(this.state.currentFileId);
        }
        return cf;
    }

    getFileFromId(id){
        let cf = false;
        for (let i in this.fileList){
            if (this.fileList[i].id == id){
                cf = this.fileList[i];
                break;
            }
        }
        return cf;
    }

    startCurateAfterAdd(fileId){
        console.log('startCurateAfterAdd fileId: ' + fileId);
        this.setState({currentFileId: fileId, navTab: 'curateAdd'});
    }

    startCurate(fileId){
        console.log('startCurate fileId: ' + fileId);
        this.setState({currentFileId: fileId, navTab: 'curate'});
    }

    startDetailView(fileId){
        this.setState({currentFileId: fileId, navTab: 'detail'});
    }

    startDownload(fileId){
        const file = this.getFileFromId(fileId);
        this.gc.downloadFile(this.getDownload, file);
    }

    getDownload(response){
        console.log('in get download');
    }



    addToRepository(fileId){
        const cf = this.getFileFromId(fileId);
        this.addFile = cf;    // so we know which file we're dealing with later
        if (cf !== false){
            const folder = cf.getFolderDate();
            this.gc.getOrCreateDateFolder(this.addFileToFolder, folder);
            this.setState({waiting: true});
        }
    }

    addFileToFolder(folderId){
        const cf = this.addFile;
        console.log('date folder created (or gotten) - folder id:' + folderId);
        if (cf !== false && folderId !== false){
            this.gc.moveFileToFolder(this.addedToRepository, folderId, cf);
        }
    }

    addedToRepository(){
        const file = this.addFile;
        // we need to upadte the file with at least something that tells us the type of file it is in case the user
        // cancels the curation - see issue #12
        file.populateAutoFields();
        this.gc.updateFileAppProperties(this.readyToCurate, file);
    }

    readyToCurate(){
        const file = this.addFile;
        this.addFile = false;
        this.setState({waiting: false});
        this.startCurateAfterAdd(file.id);
    }

    // search handler
    searchHandler(searchModel) {
        // do a search based on the search model
        this.searchModel = searchModel;
        this.fileList = [];
        this.setState({fileListCount: 0, waiting: true, currentError: false});
        this.gc.searchForFiles(this.setFileList, this.searchModel.getQ());
    }

    // see #19
    uncuratedHandler(searchModel) {
        // we update the search model even though we're not using its contents for this search,
        // so that the state of the search model is preserved
        this.searchModel = searchModel;
        this.fileList = [];
        this.setState({fileListCount: 0, waiting: true, currentError: false});
        this.gc.searchForFiles(this.setFileList, this.searchModel.getUncuratedQ());
    }

    setCommonKeywords() {
        const mainKeywords =
            [
                'perched',
                'in-flight',
                'mating',
                'nesting',
                'displaying',
                'feeding',
                'rearing',
                'lead',
                'adult',
                'juvenile',
                'release',
                'field-crew',
                'deceased',
                'egg',
                'scenic',
                'landscape',
                'portrait'
            ].sort();
        this.commonKeywords = [
            'CACO',
            'BAEA'
        ].concat(mainKeywords);
    }

    setCommonColors() {
        this.commonColors =
            [
                'red',
                'orange',
                'yellow',
                'green',
                'blue',
                'purple',
                'brown',
                'black',
                'white',
                'grey',
                'pink'
            ].sort();
    }


    render() {
        const module = this.state.navTab;
        let mainSection = '';
        let debugSection = '';
        const searchOrder = this.searchModel.getSearchOrder();
        if (this.state.debugInfo !== ''){
            debugSection =
                <div class="debugInfo">{this.state.debugInfo}</div>;
        }
        if (this.state.signedIn && this.state.currentUser !== false){
            if (module === 'search'){
                mainSection =
                    <div>
                    <SearchForm
                        searchHandler={this.searchHandler}
                        searchModel={this.searchModel}
                        commonKeywords={this.commonKeywords}
                        commonColors={this.commonColors}
                        uncuratedHandler={this.uncuratedHandler}
                        />
                    <GalleryDisplay
                        files={this.fileList}
                        detailHandler={this.startDetailView}
                        hasMore={this.state.hasMoreFiles}
                        getMoreHandler={this.getMoreFiles}
                        waiting={this.state.waiting}
                        searchString={this.searchModel.searchString}
                        errorMessage={this.state.currentError}
                        searchOrder={searchOrder}
                    />
                    </div>
            }
            else if (module === 'curate' || module === 'curateAdd' || module === 'detail'){
                let canCurate = true;
                if (module === 'detail'){
                    canCurate = this.state.currentUser.canCurate();
                }
                let file = this.getCurrentFile();
                console.log('curate/detail - file is false is ' + (file===false?'T':'F') + ' file list size is ' + this.fileList.length);
                if (file !== false) {

                    mainSection =
                        <DetailView
                            view={module}
                            file={file}
                            changeHandler={this.curateChangeHandler}
                            cancelHandler={this.afterCurate}
                            startCurate={this.startCurate}
                            canCurate={canCurate}
                            waiting={this.state.waiting}
                            commonKeywords={this.commonKeywords}
                            commonColors={this.commonColors}
                            ownerMetaData={this.state.ownerMetaData}
                        />;
                }
            }
            else if (module === 'add'){
                mainSection =
                    <InboxDisplay
                        files={this.fileList}
                        addToRepository={this.addToRepository}
                        waiting={this.state.waiting}
                        errorMessage={this.state.currentError}
                        />;
            }
        }
        else {
            let am = 'Authenticating User';
            if (this.state.signedIn && this.state.currentUser === false){
                am = 'Getting User Info';
            }
            mainSection =
                <>
                    <div className="authMessage">{am}</div>
                    <DamSpinny/>
                </>;
        }
        return (
        <div id="mainDiv">
            <DamHeader
                onItem={module}
                clickHandler={this.navClickHandler}
                signedIn={this.state.signedIn}
                user={this.state.currentUser}
            />
            {debugSection}
            {mainSection}
        </div>
        );
    }

}

export default Dam;