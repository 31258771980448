
export class DamUser {

    constructor(){
        this.name = '';
        this.photoLink = false;
        this.email = false;
        this.role = false;
    }

    hasName() {
        return this.name !== '';
    }

    populateFromGoogleUser(gUser){
        if (gUser.displayName){
            this.name = gUser.displayName;
        }
        if (gUser.photoLink){
            this.photoLink = gUser.photoLink;
        }
        if (gUser.emailAddress){
            this.email = gUser.emailAddress;
        }
    }

    setRole(role){
        this.role = role;
    }

    canAdd(){
        return this.role !== false && (this.role === 'organizer' || this.role === 'fileOrganizer');
    }

    canCurate() {
        return this.canAdd();   // for now, same permissions as add, might  change in the future
    }

    hasRole() {
        return this.role !== false;
    }

    getDisplayRole(){
        let dr = '';
        if (this.hasRole()){
            if (this.role === 'organizer') {
                dr = 'Manager';
            }
            else if (this.role === 'fileOrganizer'){
                dr = 'Curator';
            }
            else if (this.role === 'reader'){
                dr = 'Searcher';
            }
        }
        return dr;
    }

}