import {DamMetaDataModel} from "./DamMetaDataModel";

export class DamFile extends DamMetaDataModel{

    constructor(){
        super();
        this.name = '';
        this.id = false;
        this.created = false;
        this.modified = false;
        this.parents = false;

        // sort of metadata fields
        this.mediaDateTime = false;

        // file type - part of meta data
        this.fileType = 'file';

        // other
        this.downloadLink = false;
        this.previewLink = false;   // we only expose this for videos
        this.size = false;

        // folder and trash stuff
        this.trashed = false;
        this.folder = false;

    }

    hasThumbnail() {
        return false;
    }

    getFileTypeShort(){
        let l = '';

        if (this.fileType.length > 0){
            l = this.fileType.substr(0, 1).toLocaleUpperCase();
        }
        return l;
    }

    populateFromGoogleFile(fObj) {
        this.id = fObj.id;
        this.name = fObj.originalFilename;
        /*
            note that createdTime refers to the time it was added to Drive, but
            modified time refers to the last time the file's contents were modified
            (which includes metadata changes)

            we'll rely on media time for image or video creation
         */
        if (fObj.modifiedTime && fObj.modifiedTime.length > 0){
            this.modified = new Date(fObj.modifiedTime);
        }
        if (fObj.createdTime && fObj.createdTime.length > 0){
            this.created = new Date(fObj.createdTime);
        }
        if (fObj.parents){
            // console.log('parents: ' + fObj.parents);
            this.parents = fObj.parents;
        }
        if (fObj.description){
            this.description = fObj.description;
        }
        // populate meta data members from app properties
        if (fObj.appProperties){
            const ap = fObj.appProperties;
            this.setMetaDataFromAppProperties(ap);
        }

        if (fObj.webContentLink){
            this.downloadLink = fObj.webContentLink;
        }
        if (fObj.size){
            this.size = fObj.size;
        }
        if (fObj.webViewLink){
            this.previewLink = fObj.webViewLink;
        }

        // trashed and folder stuff
        if (fObj.mimeType && fObj.mimeType === 'application/vnd.google-apps.folder'){
            this.folder = true;
        }
        if (fObj.trashed && fObj.trashed === true){
            this.trashed = true;
        }
    }

    hasId() {
        return this.id !== false;
    }

    hasDescription() {
        return this.description && this.description.length > 0;
    }

    zeroPad(val){
        let zVal = '' + val;
        if (val < 10){
            zVal = '0' + zVal;
        }
        return zVal;
    }

    getDisplayDate(date){
        let ds = '';
        if (date !== false){
            ds = (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
        }
        return ds;
    }

    getDisplayTime(date){
        let ts = '';
        if (date !== false){
            ts = this.zeroPad(date.getHours()) + ':' + this.zeroPad(date.getMinutes()) + ':' + this.zeroPad(date.getSeconds());
        }
        return ts;
    }

    /*
        the next two function getFolderYear and getFolderDate are used
        to figure out where the file should live - use mediaTime if available as that will be when the image
        or video was created - if that's not available, use created time
     */
    getFolderYear() {
        let y = '';
        if (this.mediaDateTime !== false){
            y = this.mediaDateTime.getFullYear();
        }
        else if (this.created !== false){
            y = this.created.getFullYear();
        }
        return y;
    }

    getFolderDate() {
        let d = '';
        if (this.mediaDateTime !== false){
            d = this.mediaDateTime.getFullYear() + '-' + this.zeroPad((this.mediaDateTime.getMonth() + 1)) + '-' + this.zeroPad(this.mediaDateTime.getDate());
        }
        if (this.created !== false){
            d = this.created.getFullYear() + '-' + this.zeroPad((this.created.getMonth() + 1)) + '-' + this.zeroPad(this.created.getDate());
        }
        return d;
    }

    getCreatedDateTime() {
        return this.getDisplayDate(this.created) + ' ' + this.getDisplayTime(this.created);
    }

    hasMediaDate() {
        return this.mediaCreationDate !== '' || this.mediaDateTime !== false;
    }

    getMediaDateTime() {
        let dt = 'unknown media date';
        if (this.mediaCreationDate !== ''){
            dt = this.mediaCreationDate;
        }
        else if (this.mediaDateTime !== false){
            dt = this.getDisplayDate(this.mediaDateTime) + ' ' + this.getDisplayTime(this.mediaDateTime);
        }
        return dt;
    }

    getMediaDate() {
        let dt = 'date unknown';
        if (this.mediaCreationDate !== ''){
            dt = this.mediaCreationDate;
        }
        else if (this.mediaDateTime !== false){
            dt = this.getDisplayDate(this.mediaDateTime);
        }
        return dt;
    }

    getCreatedDate() {
        let dt = '';
        if (this.created !== false){
            dt = this.getDisplayDate(this.created);
        }
        return dt;
    }

    // keeping these two methods here and not in the underlying meta data model 'cos there may well be things
    // that are file specific that go in here too - not sure yet.

    // return an instance of an object representing meta data
    getEditableMetaDataInstance() {
        return this.getCurateMetaDataObject();
    }

    hasDownloadLink() {
        return this.downloadLink !== false;
    }

    hasSize() {
        return this.size !== false;
    }

    formatNumber(number){
        return Number(number.toFixed(1)).toLocaleString();
    }

    getDisplaySize() {
        let ds = '';
        if (this.hasSize()){
            if (this.size < 1024){
                ds = this.size.toLocaleString() + ' bytes';
            }
            else if (this.size < (1024 * 1024)) {
                ds = this.formatNumber(this.size / 1024) + ' Kb';
            }
            else if (this.size < (1024 * 1024 * 1024)) {
                ds = this.formatNumber(this.size / 1024 / 1024 ) + ' Mb';
            }
            else {
                ds = this.formatNumber(this.size / 1024 / 1024 / 1024) + ' Gb';
            }
        }
        return ds;
    }

    isImageFile(){
        return false;
    }

    isVideoFile(){
        return false;
    }

    hasPreviewLink() {
        return this.previewLink !== false;
    }

    isFolder(){
        return this.folder;
    }

    isTrashed(){
        return this.trashed;
    }

}