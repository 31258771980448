import {DamImageFile} from "./DamImageFile";

export class DamVideoFile extends DamImageFile{

    constructor() {
        super();
        this.runningTime = false;
        // part of metadata
        this.fileType = 'video';
    }

    populateFromGoogleFile(fObj) {
        super.populateFromGoogleFile(fObj);
        if (fObj.videoMediaMetadata) {
            const v = fObj.videoMediaMetadata;
            if (v.width){
                this.width = v.width;
            }
            if (v.height){
                this.height = v.height;
            }
            if (v.durationMillis){
                this.runningTime = v.durationMillis;
            }
        }
    }

    isImageFile() {
        return false;
    }

    isVideoFile() {
        return true;
    }

    hasRunningTime() {
        return this.runningTime !== false;
    }

    getDisplayRunningTime() {
        let rt = '';
        const seconds = parseInt(this.runningTime / 1000);
        if (seconds < 60){
            rt = seconds + ' secs';
        }
        else {
            const mins = parseInt(seconds / 60);
            const remSecs = seconds % 60;
            rt = mins + ' mins, ' + remSecs + ' secs';
        }
        return rt;
    }

    getDisplayDimensions() {
        let dd = super.getDisplayDimensions();
        let vd = '';
        if (this.hasDimensions()){
            if (this.height == 4320){
                vd += '8k';
            }
            else if (this.height == 3160){
                vd += '6k';
            }
            else if (this.height == 2160){
                if (this.width == 3840){
                    vd += 'UHD';
                }
                else if (this.width == 4096){
                    vd += '4k';
                }
            }
            else if (this.height == 1152){
                vd += '2k';
            }
            else if (this.height == 1080){
                vd += 'full HD';
            }
            else if (this.height == 720){
                vd += 'HD';
            }
            else if (this.height == 480){
                vd += 'SD';
            }
        }
        if (vd.length > 0){
            dd += ' - ' + vd;
        }
        return dd;
    }


}