import React from 'react';


class DataLists extends React.Component{

    constructor(props){
        super(props);
    }

    render() {
        let ownerOptions = [];
        for (let name in this.props.ownerMetaData){
            ownerOptions.push(
                <option value={name}/>
            );
        }

        return (
          <div>
              <datalist id="locations">
                  <option value="Big Sur"/>
                  <option value="Big Sur Coast"/>
                  <option value="Pinnacles National Park"/>
                  <option value="San Simeon"/>
                  <option value="VWS Office"/>
                  <option value="Field Trip"/>
                  <option value="Education Camp"/>
                  <option value="Monterey Bay"/>
              </datalist>
              <datalist id="owners">
                  {ownerOptions}
              </datalist>
              <datalist id="rights">
                  <option value="Full"/>
                  <option value="See Owner"/>
              </datalist>
          </div>
        );


    }

}

export default DataLists;