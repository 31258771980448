/**
 * a class that this code will be based off - basically a DAM class, rather than a Google glass, so that if
 * we switch providers, only this area will need to change (in theory!)
 */
import {DamFile} from "./DamFile";


export class DamImageFile extends DamFile {

    constructor() {
        super();
        this.thumbUrl = '';
        this.width = false;
        this.height = false;

        // override type - part of metadata
        this.fileType = 'image';
    }

    isImageFile(){
        return true;
    }

    populateFromGoogleFile(fObj) {
        super.populateFromGoogleFile(fObj);
        if (fObj.imageMediaMetadata){
            this.width = fObj.imageMediaMetadata.width;
            this.height = fObj.imageMediaMetadata.height;
            console.log('image metadata: ' + JSON.stringify(fObj.imageMediaMetadata));
            /*
                see issue #1 for explanations of what's going on below
             */
            if (fObj.imageMediaMetadata.time && fObj.imageMediaMetadata.time.length > 0) {
                const it = fObj.imageMediaMetadata.time;
                // see if it conforms to the exif date time format - 2021:02:17 11:10:13
                const exifFormat = /(\d{4})\:(\d{2})\:(\d{2})\s(\d{2})\:(\d{2})\:(\d{2})/;
                const dateParts = it.match(exifFormat);
                if (dateParts !== null && dateParts.length === 7){
                    this.mediaDateTime = new Date(dateParts[1], (dateParts[2] -1), dateParts[3], dateParts[4], dateParts[5], dateParts[6]);
                    console.log('date from dateparts ' + this.mediaDateTime);
                }
                else {
                    // let's see if it's in the Adobe "save for web" format - 2021-02-01T02:54:48+16:00 - or 2021-03-14T22:38:24-07:00
                    const adobeFormat = /(\d{4})-(\d{2})-(\d{2})T(\d{2})\:(\d{2})\:(\d{2})([\+|\-])(\d{2})\:\d{2}/;
                    const aDateParts = it.match(adobeFormat);
                    if (aDateParts !== null && aDateParts.length === 9){
                        // get the time zone difference in there! - the JS Date constructor copes with hours
                        // spilling past 23 and below 0 by setting it to the next or previous day
                        let hours = parseInt(aDateParts[4]);
                        if (aDateParts[7] === '+'){
                            hours += parseInt(aDateParts[8]);
                        }
                        else {
                            hours -= parseInt(aDateParts[8]);
                        }
                        this.mediaDateTime = new Date(aDateParts[1], (aDateParts[2] -1), aDateParts[3], hours, aDateParts[5], aDateParts[6]);
                        console.log('date from aDateParts ' + this.mediaDateTime);
                    }
                }
                if (this.mediaDateTime === false){
                    // we haven't found one of the known formats, lets just try and see if the format can be parsed by Date
                    const d = new Date(it);
                    // if it was able to parse, then d.getTime will return a number
                    if (!isNaN(d.getTime())){
                        this.mediaDateTime = d;
                    }

                }
            }
        }
        if (fObj.hasThumbnail){
            if (fObj.thumbnailLink){
                this.thumbUrl = fObj.thumbnailLink;
            }
            /*
            else {
                this.thumbUrl = 'https://drive.google.com/thumbnail?id=' + this.id;
            }
             */
        }
    }

    hasThumbnail() {
        return this.thumbUrl !== false;
    }

    hasDimensions() {
        return (this.width !== false && this.height !== false);
    }

    populateAutoFields() {
        super.populateAutoFields(); // unnecessary as it's just a placeholder
        if (this.hasDimensions()){
            let orientation;
            if (this.width === this.height){
                orientation = 'Q';      // Square
            }
            else if (this.width > this.height){
                orientation = 'L';      // Landscape
            }
            else {
                orientation = 'P';      // Portrait
            }
            this.orientation = orientation;
        }
    }

    getLargerThumbnail() {
        const tRE = /(.*)\=s220$/;
        let tUrl = this.thumbUrl;
        const urlParts = this.thumbUrl.match(tRE);
        if (urlParts !== null && urlParts.length === 2) {
            tUrl = urlParts[1] + '=s440';
        }
        return tUrl;
    }

    getDisplayDimensions() {
        let dd = '';
        if (this.hasDimensions()){
            dd = Number(this.width).toLocaleString() + ' x ' + Number(this.height).toLocaleString() + ' px';
        }
        return dd;
    }

}
