import React from 'react';

class CommonKeywordsDisplay extends React.Component {

    constructor(props){
        super(props);
        this.hasKeyword = this.hasKeyword.bind(this);
        this.keywordClicked = this.keywordClicked.bind(this);
    }

    hasKeyword(keyword){
        let ksArray = [];
        if (this.props.keywordString){
            ksArray = this.props.keywordString.split(' ');
        }
        const has = ksArray.indexOf(keyword) > -1;
        return has;
    }

    keywordClicked(e){
        const keyword = e.target.innerHTML.substr(2);
        let newString;
        if (this.hasKeyword(keyword)){
            let ksArray = this.props.keywordString.split(' ');
            const index = ksArray.indexOf(keyword);
            ksArray.splice(index, 1);
            newString = ksArray.join(' ');
        }
        else {
            if (this.props.keywordString.length > 0){
                newString = this.props.keywordString + ' ' + keyword;
            }
            else {
                newString = keyword;
            }
        }
        const newE = {
            target: {
                name: this.props.target,
                value: newString
            }
        }
        // console.log('newE ' + newE.target.name + ' ' + newE.target.value);
        this.props.targetHandler(newE);
    }

    render() {
        const cc = this.props.commonKeywords;
        let keywords = [];
        if (this.props.title && this.props.title.length > 0){
            keywords.push(
              <div className="keywordTitle" key="title">{this.props.title}</div>
            );
        }
        for (let i in cc){
            if (this.hasKeyword(cc[i])){
                keywords.push(
                    <div className="keyword keywordOff" key={i} onClick={this.keywordClicked}>
                        - {cc[i]}
                    </div>
                );
            }
            else {
                keywords.push(
                    <div className="keyword keywordOn" key={i} onClick={this.keywordClicked}>
                        + {cc[i]}
                    </div>
                );
            }
        }
        return (
            <div className="keywordContainer">
                {keywords}
            </div>
        );
    }
}

export default CommonKeywordsDisplay;