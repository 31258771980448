import React from 'react';
import {DamImageFile} from '../classes/DamImageFile';
import GalleryItemDisplay from "./GalleryItemDisplay";
import DamSpinny from "./DamSpinny";

/**
 * Top level component for DAM project - it's the app container basically.
 *
 *
 */

class GalleryDisplay extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        const files = this.props.files;
        let resultCount = files.length;
        if (this.props.hasMore){
            resultCount += '+';
        }
        let searchOrder = '';

        if (!this.props.waiting && this.props.errorMessage === false && files.length > 0){
            searchOrder =
                    <div className="gallerySearchOrder">Result count: {resultCount}, result order: {this.props.searchOrder}</div>;
        }

        let items = [];
        for (let i in files){
            items.push(
                <GalleryItemDisplay
                    key={i}
                    file={files[i]}
                    detailHandler={this.props.detailHandler}
                    searchString={this.props.searchString}
                />
            );
        }
        let waiting = '';
        if (this.props.waiting){
            // stick out a spinny!
            waiting =
                <DamSpinny/>;

        }
        else if (items.length === 0){
            items =
                <div className="authMessage">No Files Found</div>;
        }
        console.log('em ' + this.props.errorMessage);
        if (this.props.errorMessage !== false){
            items =
                <div className="errorMessage">{this.props.errorMessage}</div>;
        }
        let hasMoreButton = '';
        if (this.props.hasMore){
            hasMoreButton =
                <div className="divButton" onClick={this.props.getMoreHandler}>More...</div>
        }
        return (
            <div className="mainSection">
                <div className="sectionTitle">Files</div>
                {searchOrder}
                <div className="galleryDisplayContainer">
                    {items}
                </div>
                {hasMoreButton}
                {waiting}
            </div>
        );
    }

}

export default GalleryDisplay;