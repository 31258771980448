import React from 'react';
import DataLists from "./DataLists";
import CommonKeywordsDisplay from "./CommonKeywordsDisplay";
import DamSpinny from "./DamSpinny";

class CurateForm extends React.Component{

    constructor(props) {
        super(props);
        this.handleTextChange = this.handleTextChange.bind(this);
        this.handleNoCommaTextChange = this.handleNoCommaTextChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
        const file = this.props.file;
        let initialState = {};
        if (file){
            initialState = file.getEditableMetaDataInstance();
            initialState.fileId = file.id;
            initialState.description = file.description;
            //
            if (file.hasMediaDate() && initialState.mediaCreationDate == ''){
                initialState.mediaCreationDate = file.getMediaDate();
            }
        }
        this.state = initialState;
    }

    handleNoCommaTextChange(e){
        const v = e.target.value;
        // basically ignore commas
        if (v.indexOf(',') === -1){
            this.handleTextChange(e);
        }
    }

    handleTextChange(e){
        const name = e.target.name;
        this.setState({[name]: e.target.value});
    }

    handleSubmit(e){
        // let file = this.props.file;
        const state = this.state;
        this.props.changeHandler(state);
    }

    handleCancel(){
        this.props.cancelHandler();
    }

    handleCheckboxChange(e){
        const name = e.target.name;
        const val = e.target.value;
        if (this.state[name] === val){
            this.setState({[name]: ''});
        }
        else {
            this.handleTextChange(e);
        }
    }



    render() {

        let submitButton = '';
        if (this.props.waiting){
            submitButton =
                <DamSpinny
                    size="2"
                />;
        }
        else {
            submitButton =
                <input type="button" className="buttonButton" name="subButton" value="Save Curation" onClick={this.handleSubmit}/>;
        }

        return (
            <div>
                <DataLists
                    ownerMetaData={this.props.ownerMetaData}
                />
                <form onSubmit={this.handleSubmit}>
                    <div className="modalSubtitle">Curate Form</div>
                    <div className="formInstructions">Separate subject identities, keywords and colors with spaces only (no commas!)</div>
                    <div className="detailCell">
                        <div className="detailLabel">Subject Identities <span className="detailHint">(eg stud ids, people's names)</span></div>
                        <input className="curateFormText" type="text" name="studIds" value={this.state.studIds} maxLength="120" onChange={this.handleNoCommaTextChange}/>
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">Location <span className="detailHint">(select from list or type new one)</span></div>
                        <input className="curateFormText" type="text" list="locations" name="location" value={this.state.location} maxLength="120" onChange={this.handleTextChange}/>
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">Owner <span className="detailHint">(select from list or type new one)</span></div>
                        <input className="curateFormText" type="text" list="owners" name="owner" value={this.state.owner} maxLength="120" onChange={this.handleTextChange}/>
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">Creator <span className="detailHint">(select from list or type new one)</span></div>
                        <input className="curateFormText" type="text" list="owners" name="creator" value={this.state.creator} maxLength="120" onChange={this.handleTextChange}/>
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">Media Creation Date</div>
                        <input className="curateFormText" type="text" name="mediaCreationDate" value={this.state.mediaCreationDate} maxLength="120" onChange={this.handleTextChange}/>
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">Internal Rights</div>
                        <input className="curateFormText" type="text" list="rights" name="internalRights" value={this.state.internalRights} maxLength="120" onChange={this.handleTextChange}/>
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">External Rights</div>
                        <input className="curateFormText" type="text" list="rights" name="externalRights" value={this.state.externalRights} maxLength="120" onChange={this.handleTextChange}/>
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">Model Releases</div>
                        <input className="curateFormText" type="text" name="modelReleaseFolder" placeholder="location of releases" value={this.state.modelReleaseFolder} maxLength="120" onChange={this.handleTextChange}/>
                        <br/>
                        <span className="detailReleaseStatus">
                        <input type="checkBox" name="modelReleaseComplete" value="complete"
                               onChange={this.handleCheckboxChange}
                               checked={this.state.modelReleaseComplete === 'complete'}
                        />Complete
                        <input type="checkBox" name="modelReleaseComplete" value="incomplete"
                               onChange={this.handleCheckboxChange}
                               checked={this.state.modelReleaseComplete === 'incomplete'}
                        />Incomplete
                        </span>
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">Dominant Colors <span className="detailHint">(select from buttons or type new ones)</span></div>
                        <input className="curateFormText" type="text" name="dominantColors" value={this.state.dominantColors} maxLength="120" onChange={this.handleNoCommaTextChange}/>
                        <CommonKeywordsDisplay
                            commonKeywords={this.props.commonColors}
                            keywordString={this.state.dominantColors}
                            targetHandler={this.handleTextChange}
                            target="dominantColors"
                        />
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">Keywords <span className="detailHint">(select from buttons or type new ones)</span></div>
                        <input className="curateFormText" type="text" name="keywords" value={this.state.keywords} maxLength="120" onChange={this.handleNoCommaTextChange}/>
                        <CommonKeywordsDisplay
                            commonKeywords={this.props.commonKeywords}
                            keywordString={this.state.keywords}
                            targetHandler={this.handleTextChange}
                            target="keywords"
                        />
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">Description</div>
                        <input className="curateFormText" type="text" name="description" value={this.state.description} onChange={this.handleTextChange}/>
                    </div>
                    <div className="detailCell">
                        <div className="detailLabel">File Type <span className="detailHint">(only change when necessary)</span></div>
                        <span className="detailFileTypeStatus">
                                <input type="radio" name="fileType" value="image"
                                       checked={this.state.fileType === 'image'}
                                       onChange={this.handleTextChange}
                                /> Image
                                <input type="radio" name="fileType" value="video"
                                       checked={this.state.fileType === 'video'}
                                       onChange={this.handleTextChange}
                                /> Video
                                <input type="radio" name="fileType" value="file"
                                       checked={this.state.fileType === 'file'}
                                       onChange={this.handleTextChange}
                                /> Other
                        </span>
                    </div>
                    {submitButton}
                </form>
            </div>
    );

    }

}

export default CurateForm;