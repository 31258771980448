import React from 'react';

class DamHeader extends React.Component {

    constructor(props) {
        super(props);
        this.itemClickHandler = this.itemClickHandler.bind(this);
    }

    itemClickHandler(tabName) {
        this.props.clickHandler(tabName);
    }

    render() {
        const user = this.props.user;
        let addItem = 'navBarItem';
        let searchItem = 'navBarItem';
        if (this.props.onItem === 'add'){
            addItem += ' navBarItemOn';
        }
        else if (this.props.onItem === 'search'){
            searchItem += ' navBarItemOn';
        }

        let si = this.props.signedIn?'Signed In':'Signed Out';
        if (user !== false && user.hasName()){
            si = user.name;
            if (user.hasRole()){
                si += ' (' + user.getDisplayRole() + ')';
            }
        }

        let nav = '';
        /* only show navigation if the user can both add and search */
        if (user !== false && user.canAdd()){
            nav =
                <>
                    <div className={addItem} onClick={() => this.itemClickHandler('add')}>Add</div>
                    <div className={searchItem} onClick={() => this.itemClickHandler('search')}>Search</div>
                </>;
        }

        return (
            <div className="navBarContainer">
                <div className="navUserStatus">{si}</div>
                <div className="navItemsContainer">
                    <div className="navAppName">VWS DAM</div>
                    {nav}
                </div>
            </div>
        );
    }

}

export default DamHeader;
